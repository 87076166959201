var InputMixin = {

  // --- Props and States --- //
  interval: null,

  PropTypes: {
    id: React.PropTypes.string,
    label: React.PropTypes.string,
    name: React.PropTypes.string,
    backend_name: React.PropTypes.string,
    placeholder: React.PropTypes.string,
    className: React.PropTypes.string,
    autosave: React.PropTypes.bool,
    autoselect: React.PropTypes.bool,
    url: React.PropTypes.string,
    method: React.PropTypes.string,
    value: React.PropTypes.bool,
    editable: React.PropTypes.bool,
    onFocus: React.PropTypes.func,
    onChange: React.PropTypes.func,
    onBlur: React.PropTypes.func,
    onKeyUp: React.PropTypes.func,
    onKeyDown: React.PropTypes.func,
    type: React.PropTypes.string,
    disabled: React.PropTypes.bool,
    errors: React.PropTypes.array,
    options: React.PropTypes.array,
    optionsUrl: React.PropTypes.string,
    valueParam: React.PropTypes.string,
    textParam: React.PropTypes.string,
    minValue: React.PropTypes.string,
    maxValue: React.PropTypes.string,
    multiple: React.PropTypes.bool,
    size: React.PropTypes.number,
    emptyPreview: React.PropTypes.bool,
    readOnly: React.PropTypes.bool,
    dateFormat: React.PropTypes.string,
    widgetPositioning: React.PropTypes.object,
    disablePaste: React.PropTypes.bool,
    mandatory: React.PropTypes.bool,
    autocomplete: React.PropTypes.string,
    maxLength: React.PropTypes.number,
    disableToolbar: React.PropTypes.bool,
    styleObj: React.PropTypes.object,
    clearSearch: React.PropTypes.bool,
    secondsToSave: React.PropTypes.int
  },

  getDefaultProps: function() {
    return {
      id: '',
      label: null,
      name: 'name',
      backend_name: '',
      autosave: false,
      method: 'PUT',
      value: '',
      options: [],
      disabled: false,
      editable: false,
      errors: null,
      minValue: null,
      maxValue: null,
      multiple: false,
      size: false,
      emptyPreview: true,
      readOnly: false,
      dateFormat: 'DD/MM/YYYY',
      widgetPositioning: {horizontal: 'auto', vertical: 'auto'},
      disablePaste: false,
      mandatory: false,
      autocomplete: 'on',
      secondsToSave: 2,
      step: "any"
    }
  },

  getInitialState: function() {
    return {
      value: this.props.value,
      saved_value: this.props.value,
      saved: null,
      saving: false,
      changed: false,
      interval: null,
      secondsRemaining: null,
      errors: this.props.errors
    }
  },

  // --- Component Lifecycle --- //

  componentDidMount: function() {
    this.renderRequiredIcon();
  },

  componentWillReceiveProps: function(nextProps) {
    this.setState({
      value: nextProps.value,
      errors: nextProps.errors
    })
  },

  // --- Render Methods --- //

  renderLabel: function() {
    if (!!this.props.label) {
      return (
        <label ref="input-label" className="control-label" htmlFor={this.getId()} style={this.props.style}>
          <span ref='label-text'>{this.props.label}</span>
          {this.renderSaving()}
          {this.renderSavedLabel()}
          {this.renderHelpText()}
        </label>
      )
    }
  },

  renderHelpText: function() {
    if (!!this.state.errors) {
      var error = this.state.errors;
      error = (error instanceof Array) ? error[0] : error;
      return (<span className="help-block" style={{'fontSize': '12px', 'margin': '0'}}>{error}</span>);
    }

    if (!!this.props.helptext) {
      if (this.props.helptext.constructor === Array) {
        var helptext = [];
        for (var i = 0; i < this.props.helptext.length; i++) {
          var item = this.props.helptext[i];
          helptext.push(
            <p key={i} className="help-block" style={{'fontSize': '12px', 'margin': '0', 'fontWeight': 'normal'}}>
              {item}
            </p>
          )
        }

        return <div>{helptext}</div>;
      } else {
        return (
          <p className="help-block" style={{'fontSize': '12px', 'margin': '0', 'fontWeight': 'normal'}}>
            {this.props.helptext}
          </p>
        )
      }
    }
  },

  renderError: function() {
    if (!!this.props.errors){
      return (<span className="help-block" style={{'fontSize': '12px', 'margin': '3px 0 0 0'}}>{this.props.errors}</span>);
    }

    if (!!this.state.errors){
      return (<span className="help-block" style={{'fontSize': '12px', 'margin': '3px 0 0 0'}}>{this.state.errors}</span>);
    }
  },

  renderSaving: function() {
    if (this.state.saving) {
      return (
        <span className="save-status" style={{'color': '#7f7f7f', 'padding': '0 10px', 'fontSize': '13px'}}>{InputTranslations['inputmixin']['Saving'] || 'Saving'}...</span>
      )
    }
  },

  renderSavedLabel: function() {
    if (this.state.saved == true) {
      return (
        <span className="save-status" style={{'color': 'green', 'padding': '0 10px', 'fontSize': '13px'}}>{InputTranslations['inputmixin']['Saved'] || 'Saved'}</span>
      )
    }
  },

  renderRequiredIcon: function() {
    if (!!this.props.mandatory) {
      var $requiredNode = $(ReactDOM.findDOMNode(this.refs['input-label'])).find('.required-icon');
      if (!!$requiredNode) {
        $requiredNode.remove();
      }

      var $domNode = $(ReactDOM.findDOMNode(this.refs['label-text']));
      $domNode.append("<span class='required-icon' style='font-size: 16px'>*</span>")
    }
  },

  // --- Callback Functions --- //

  handleSave: function(value, forceSave) {
    var shouldSave = (value != this.state.saved_value) &&
        !(this.state.saved_value == null && value == "") &&
        !(this.state.saved_value == "" && value == null)

    if (shouldSave || forceSave) {
      this.setState({
        saving: true,
        saved: null,
        secondsRemaining: null
      });
      this.handleSubmit(value);
    }
  },

  handleSubmit(value) {
    var fd = this.buildPostData(value);
    $.ajax({
      url: this.props.url,
      method: this.props.method,
      dataType: 'JSON',
      data: fd,
      enctype: 'multipart/form-data',
      success: this.onSave,
      error: this.onFail,
      processData: false,
      contentType: false
    })
  },

  buildPostData: function(value) {
    var attributes = {};
    var fd = new FormData();

    if (!!value && !!value.type) {
      fd.append(this.props.backend_name || this.props.name, value);
    } else {
      attributes[this.props.backend_name || this.props.name] = value;
      fd.append('attributes', JSON.stringify(attributes));
    }
    return fd;
  },

  onSave: function(data) {
    this.setState({
      saved: true,
      saving: false,
      saved_value: this.state.value,
      errors: null
    });

    setTimeout(function() {
      this.setState({saved: null});
    }.bind(this), 1000);

    if (!!this.props.onSave)
      this.props.onSave(data);

  },

  onFail: function(xhr, status) {
    var errors = !!xhr.responseJSON?xhr.responseJSON[this.props.name]:null;
    this.setState({saved: false, saving: false, errors: errors});

    if (!!this.props.onFail)
      this.props.onFail(xhr, status);
  },

  onChange: function(event, value) {
    if (!!this.props.onChange)
      this.props.onChange(event, value);

    if (this.props.autosave) {
      this.startSaveWatch();
    }
  },

  onClear: function(event, value) {
    if (!!this.props.onClear)
      this.props.onClear(event, value);

    if (this.props.autosave) {
      this.handleSave(this.state.value);
    }
  },

  startSaveWatch: function() {
    clearInterval(this.state.interval);
    var seconds = this.props.secondsToSave;

    this.setState({secondsRemaining: seconds});
    this.state.interval = setInterval(this.tick, 1000);
  },

  tick: function() {
    this.setState({secondsRemaining: this.state.secondsRemaining - 1});
    if (this.state.secondsRemaining <= 0) {
      clearInterval(this.state.interval);
      this.handleSave(this.state.value);
    }
  },

  getId: function() {
    if (!!this.props.id) {
      return this.props.id;
    } else {
      return this.props.name;
    }
  }

};